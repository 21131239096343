import * as i from "react";
import { useEffect as ne, useState as ae } from "react";
let O = 0;
function He() {
  ne(() => {
    var e, r;
    const t = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", (e = t[0]) !== null && e !== void 0 ? e : H()), document.body.insertAdjacentElement("beforeend", (r = t[1]) !== null && r !== void 0 ? r : H()), O++, () => {
      O === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach(
        (n) => n.remove()
      ), O--;
    };
  }, []);
}
function H() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.cssText = "outline: none; opacity: 0; position: fixed; pointer-events: none", e;
}
var oe = function(e) {
  if (typeof document > "u")
    return null;
  var r = Array.isArray(e) ? e[0] : e;
  return r.ownerDocument.body;
}, R = /* @__PURE__ */ new WeakMap(), B = /* @__PURE__ */ new WeakMap(), M = {}, j = 0, Z = function(e) {
  return e && (e.host || Z(e.parentNode));
}, ce = function(e, r) {
  return r.map(function(t) {
    if (e.contains(t))
      return t;
    var n = Z(t);
    return n && e.contains(n) ? n : (console.error("aria-hidden", t, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(t) {
    return !!t;
  });
}, ie = function(e, r, t, n) {
  var a = ce(r, Array.isArray(e) ? e : [e]);
  M[t] || (M[t] = /* @__PURE__ */ new WeakMap());
  var c = M[t], l = [], u = /* @__PURE__ */ new Set(), g = new Set(a), h = function(s) {
    !s || u.has(s) || (u.add(s), h(s.parentNode));
  };
  a.forEach(h);
  var v = function(s) {
    !s || g.has(s) || Array.prototype.forEach.call(s.children, function(d) {
      if (u.has(d))
        v(d);
      else {
        var y = d.getAttribute(n), S = y !== null && y !== "false", o = (R.get(d) || 0) + 1, f = (c.get(d) || 0) + 1;
        R.set(d, o), c.set(d, f), l.push(d), o === 1 && S && B.set(d, !0), f === 1 && d.setAttribute(t, "true"), S || d.setAttribute(n, "true");
      }
    });
  };
  return v(r), u.clear(), j++, function() {
    l.forEach(function(s) {
      var d = R.get(s) - 1, y = c.get(s) - 1;
      R.set(s, d), c.set(s, y), d || (B.has(s) || s.removeAttribute(n), B.delete(s)), y || s.removeAttribute(t);
    }), j--, j || (R = /* @__PURE__ */ new WeakMap(), R = /* @__PURE__ */ new WeakMap(), B = /* @__PURE__ */ new WeakMap(), M = {});
  };
}, Ge = function(e, r, t) {
  t === void 0 && (t = "data-aria-hidden");
  var n = Array.from(Array.isArray(e) ? e : [e]), a = r || oe(e);
  return a ? (n.push.apply(n, Array.from(a.querySelectorAll("[aria-live]"))), ie(n, a, t, "aria-hidden")) : function() {
    return null;
  };
}, E = function() {
  return E = Object.assign || function(r) {
    for (var t, n = 1, a = arguments.length; n < a; n++) {
      t = arguments[n];
      for (var c in t)
        Object.prototype.hasOwnProperty.call(t, c) && (r[c] = t[c]);
    }
    return r;
  }, E.apply(this, arguments);
};
function q(e, r) {
  var t = {};
  for (var n in e)
    Object.prototype.hasOwnProperty.call(e, n) && r.indexOf(n) < 0 && (t[n] = e[n]);
  if (e != null && typeof Object.getOwnPropertySymbols == "function")
    for (var a = 0, n = Object.getOwnPropertySymbols(e); a < n.length; a++)
      r.indexOf(n[a]) < 0 && Object.prototype.propertyIsEnumerable.call(e, n[a]) && (t[n[a]] = e[n[a]]);
  return t;
}
function ue(e, r, t) {
  if (t || arguments.length === 2)
    for (var n = 0, a = r.length, c; n < a; n++)
      (c || !(n in r)) && (c || (c = Array.prototype.slice.call(r, 0, n)), c[n] = r[n]);
  return e.concat(c || Array.prototype.slice.call(r));
}
var L = "right-scroll-bar-position", N = "width-before-scroll-bar", le = "with-scroll-bars-hidden", fe = "--removed-body-scroll-bar-size";
function se(e, r) {
  return typeof e == "function" ? e(r) : e && (e.current = r), e;
}
function de(e, r) {
  var t = ae(function() {
    return {
      // value
      value: e,
      // last callback
      callback: r,
      // "memoized" public interface
      facade: {
        get current() {
          return t.value;
        },
        set current(n) {
          var a = t.value;
          a !== n && (t.value = n, t.callback(n, a));
        }
      }
    };
  })[0];
  return t.callback = r, t.facade;
}
function ve(e, r) {
  return de(r || null, function(t) {
    return e.forEach(function(n) {
      return se(n, t);
    });
  });
}
function he(e) {
  return e;
}
function me(e, r) {
  r === void 0 && (r = he);
  var t = [], n = !1, a = {
    read: function() {
      if (n)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return t.length ? t[t.length - 1] : e;
    },
    useMedium: function(c) {
      var l = r(c, n);
      return t.push(l), function() {
        t = t.filter(function(u) {
          return u !== l;
        });
      };
    },
    assignSyncMedium: function(c) {
      for (n = !0; t.length; ) {
        var l = t;
        t = [], l.forEach(c);
      }
      t = {
        push: function(u) {
          return c(u);
        },
        filter: function() {
          return t;
        }
      };
    },
    assignMedium: function(c) {
      n = !0;
      var l = [];
      if (t.length) {
        var u = t;
        t = [], u.forEach(c), l = t;
      }
      var g = function() {
        var v = l;
        l = [], v.forEach(c);
      }, h = function() {
        return Promise.resolve().then(g);
      };
      h(), t = {
        push: function(v) {
          l.push(v), h();
        },
        filter: function(v) {
          return l = l.filter(v), t;
        }
      };
    }
  };
  return a;
}
function ge(e) {
  e === void 0 && (e = {});
  var r = me(null);
  return r.options = E({ async: !0, ssr: !1 }, e), r;
}
var Q = function(e) {
  var r = e.sideCar, t = q(e, ["sideCar"]);
  if (!r)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var n = r.read();
  if (!n)
    throw new Error("Sidecar medium not found");
  return i.createElement(n, E({}, t));
};
Q.isSideCarExport = !0;
function ye(e, r) {
  return e.useMedium(r), Q;
}
var K = ge(), D = function() {
}, $ = i.forwardRef(function(e, r) {
  var t = i.useRef(null), n = i.useState({
    onScrollCapture: D,
    onWheelCapture: D,
    onTouchMoveCapture: D
  }), a = n[0], c = n[1], l = e.forwardProps, u = e.children, g = e.className, h = e.removeScrollBar, v = e.enabled, s = e.shards, d = e.sideCar, y = e.noIsolation, S = e.inert, o = e.allowPinchZoom, f = e.as, p = f === void 0 ? "div" : f, w = q(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as"]), b = d, C = ve([t, r]), m = E(E({}, w), a);
  return i.createElement(
    i.Fragment,
    null,
    v && i.createElement(b, { sideCar: K, removeScrollBar: h, shards: s, noIsolation: y, inert: S, setCallbacks: c, allowPinchZoom: !!o, lockRef: t }),
    l ? i.cloneElement(i.Children.only(u), E(E({}, m), { ref: C })) : i.createElement(p, E({}, m, { className: g, ref: C }), u)
  );
});
$.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
$.classNames = {
  fullWidth: N,
  zeroRight: L
};
var G, pe = function() {
  if (G)
    return G;
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
function be() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var r = pe();
  return r && e.setAttribute("nonce", r), e;
}
function Se(e, r) {
  e.styleSheet ? e.styleSheet.cssText = r : e.appendChild(document.createTextNode(r));
}
function we(e) {
  var r = document.head || document.getElementsByTagName("head")[0];
  r.appendChild(e);
}
var Ce = function() {
  var e = 0, r = null;
  return {
    add: function(t) {
      e == 0 && (r = be()) && (Se(r, t), we(r)), e++;
    },
    remove: function() {
      e--, !e && r && (r.parentNode && r.parentNode.removeChild(r), r = null);
    }
  };
}, Ee = function() {
  var e = Ce();
  return function(r, t) {
    i.useEffect(function() {
      return e.add(r), function() {
        e.remove();
      };
    }, [r && t]);
  };
}, U = function() {
  var e = Ee(), r = function(t) {
    var n = t.styles, a = t.dynamic;
    return e(n, a), null;
  };
  return r;
}, Re = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, X = function(e) {
  return parseInt(e || "", 10) || 0;
}, ke = function(e) {
  var r = window.getComputedStyle(document.body), t = r[e === "padding" ? "paddingLeft" : "marginLeft"], n = r[e === "padding" ? "paddingTop" : "marginTop"], a = r[e === "padding" ? "paddingRight" : "marginRight"];
  return [X(t), X(n), X(a)];
}, Ae = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return Re;
  var r = ke(e), t = document.documentElement.clientWidth, n = window.innerWidth;
  return {
    left: r[0],
    top: r[1],
    right: r[2],
    gap: Math.max(0, n - t + r[2] - r[0])
  };
}, xe = U(), Pe = function(e, r, t, n) {
  var a = e.left, c = e.top, l = e.right, u = e.gap;
  return t === void 0 && (t = "margin"), `
  .`.concat(le, ` {
   overflow: hidden `).concat(n, `;
   padding-right: `).concat(u, "px ").concat(n, `;
  }
  body {
    overflow: hidden `).concat(n, `;
    overscroll-behavior: contain;
    `).concat([
    r && "position: relative ".concat(n, ";"),
    t === "margin" && `
    padding-left: `.concat(a, `px;
    padding-top: `).concat(c, `px;
    padding-right: `).concat(l, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(u, "px ").concat(n, `;
    `),
    t === "padding" && "padding-right: ".concat(u, "px ").concat(n, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(L, ` {
    right: `).concat(u, "px ").concat(n, `;
  }
  
  .`).concat(N, ` {
    margin-right: `).concat(u, "px ").concat(n, `;
  }
  
  .`).concat(L, " .").concat(L, ` {
    right: 0 `).concat(n, `;
  }
  
  .`).concat(N, " .").concat(N, ` {
    margin-right: 0 `).concat(n, `;
  }
  
  body {
    `).concat(fe, ": ").concat(u, `px;
  }
`);
}, Be = function(e) {
  var r = e.noRelative, t = e.noImportant, n = e.gapMode, a = n === void 0 ? "margin" : n, c = i.useMemo(function() {
    return Ae(a);
  }, [a]);
  return i.createElement(xe, { styles: Pe(c, !r, a, t ? "" : "!important") });
}, Y = !1;
if (typeof window < "u")
  try {
    var T = Object.defineProperty({}, "passive", {
      get: function() {
        return Y = !0, !0;
      }
    });
    window.addEventListener("test", T, T), window.removeEventListener("test", T, T);
  } catch {
    Y = !1;
  }
var k = Y ? { passive: !1 } : !1, Me = function(e) {
  return e.tagName === "TEXTAREA";
}, J = function(e, r) {
  var t = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    t[r] !== "hidden" && // contains scroll inside self
    !(t.overflowY === t.overflowX && !Me(e) && t[r] === "visible")
  );
}, Te = function(e) {
  return J(e, "overflowY");
}, We = function(e) {
  return J(e, "overflowX");
}, V = function(e, r) {
  var t = r;
  do {
    typeof ShadowRoot < "u" && t instanceof ShadowRoot && (t = t.host);
    var n = ee(e, t);
    if (n) {
      var a = re(e, t), c = a[1], l = a[2];
      if (c > l)
        return !0;
    }
    t = t.parentNode;
  } while (t && t !== document.body);
  return !1;
}, Le = function(e) {
  var r = e.scrollTop, t = e.scrollHeight, n = e.clientHeight;
  return [
    r,
    t,
    n
  ];
}, Ne = function(e) {
  var r = e.scrollLeft, t = e.scrollWidth, n = e.clientWidth;
  return [
    r,
    t,
    n
  ];
}, ee = function(e, r) {
  return e === "v" ? Te(r) : We(r);
}, re = function(e, r) {
  return e === "v" ? Le(r) : Ne(r);
}, $e = function(e, r) {
  return e === "h" && r === "rtl" ? -1 : 1;
}, Ie = function(e, r, t, n, a) {
  var c = $e(e, window.getComputedStyle(r).direction), l = c * n, u = t.target, g = r.contains(u), h = !1, v = l > 0, s = 0, d = 0;
  do {
    var y = re(e, u), S = y[0], o = y[1], f = y[2], p = o - f - c * S;
    (S || p) && ee(e, u) && (s += p, d += S), u = u.parentNode;
  } while (
    // portaled content
    !g && u !== document.body || // self content
    g && (r.contains(u) || r === u)
  );
  return (v && (a && s === 0 || !a && l > s) || !v && (a && d === 0 || !a && -l > d)) && (h = !0), h;
}, W = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, z = function(e) {
  return [e.deltaX, e.deltaY];
}, F = function(e) {
  return e && "current" in e ? e.current : e;
}, Oe = function(e, r) {
  return e[0] === r[0] && e[1] === r[1];
}, je = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, De = 0, A = [];
function Xe(e) {
  var r = i.useRef([]), t = i.useRef([0, 0]), n = i.useRef(), a = i.useState(De++)[0], c = i.useState(function() {
    return U();
  })[0], l = i.useRef(e);
  i.useEffect(function() {
    l.current = e;
  }, [e]), i.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(a));
      var o = ue([e.lockRef.current], (e.shards || []).map(F), !0).filter(Boolean);
      return o.forEach(function(f) {
        return f.classList.add("allow-interactivity-".concat(a));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(a)), o.forEach(function(f) {
          return f.classList.remove("allow-interactivity-".concat(a));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var u = i.useCallback(function(o, f) {
    if ("touches" in o && o.touches.length === 2)
      return !l.current.allowPinchZoom;
    var p = W(o), w = t.current, b = "deltaX" in o ? o.deltaX : w[0] - p[0], C = "deltaY" in o ? o.deltaY : w[1] - p[1], m, I = o.target, x = Math.abs(b) > Math.abs(C) ? "h" : "v";
    if ("touches" in o && x === "h" && I.type === "range")
      return !1;
    var P = V(x, I);
    if (!P)
      return !0;
    if (P ? m = x : (m = x === "v" ? "h" : "v", P = V(x, I)), !P)
      return !1;
    if (!n.current && "changedTouches" in o && (b || C) && (n.current = m), !m)
      return !0;
    var _ = n.current || m;
    return Ie(_, f, o, _ === "h" ? b : C, !0);
  }, []), g = i.useCallback(function(o) {
    var f = o;
    if (!(!A.length || A[A.length - 1] !== c)) {
      var p = "deltaY" in f ? z(f) : W(f), w = r.current.filter(function(m) {
        return m.name === f.type && m.target === f.target && Oe(m.delta, p);
      })[0];
      if (w && w.should) {
        f.cancelable && f.preventDefault();
        return;
      }
      if (!w) {
        var b = (l.current.shards || []).map(F).filter(Boolean).filter(function(m) {
          return m.contains(f.target);
        }), C = b.length > 0 ? u(f, b[0]) : !l.current.noIsolation;
        C && f.cancelable && f.preventDefault();
      }
    }
  }, []), h = i.useCallback(function(o, f, p, w) {
    var b = { name: o, delta: f, target: p, should: w };
    r.current.push(b), setTimeout(function() {
      r.current = r.current.filter(function(C) {
        return C !== b;
      });
    }, 1);
  }, []), v = i.useCallback(function(o) {
    t.current = W(o), n.current = void 0;
  }, []), s = i.useCallback(function(o) {
    h(o.type, z(o), o.target, u(o, e.lockRef.current));
  }, []), d = i.useCallback(function(o) {
    h(o.type, W(o), o.target, u(o, e.lockRef.current));
  }, []);
  i.useEffect(function() {
    return A.push(c), e.setCallbacks({
      onScrollCapture: s,
      onWheelCapture: s,
      onTouchMoveCapture: d
    }), document.addEventListener("wheel", g, k), document.addEventListener("touchmove", g, k), document.addEventListener("touchstart", v, k), function() {
      A = A.filter(function(o) {
        return o !== c;
      }), document.removeEventListener("wheel", g, k), document.removeEventListener("touchmove", g, k), document.removeEventListener("touchstart", v, k);
    };
  }, []);
  var y = e.removeScrollBar, S = e.inert;
  return i.createElement(
    i.Fragment,
    null,
    S ? i.createElement(c, { styles: je(a) }) : null,
    y ? i.createElement(Be, { gapMode: "margin" }) : null
  );
}
const Ye = ye(K, Xe);
var te = i.forwardRef(function(e, r) {
  return i.createElement($, E({}, e, { ref: r, sideCar: Ye }));
});
te.classNames = $.classNames;
const Ve = te;
export {
  Ve as $,
  He as a,
  Ge as h
};
