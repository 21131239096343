import { TaskGroup } from "src/constants/ui";
import { CheckMarkCircle as CheckMarkIcon } from 'src/icons/checkmark-circle';
import { FileEdit as FileEditIcon } from 'src/icons/file-edit';
import { FolderWarning as FolderWarningIcon } from 'src/icons/folder-warning';
import { Upload as UploadIcon } from 'src/icons/upload';
import { UserPlus as AssignSigneeIcon } from 'src/icons/user-plus';
import { ValueOf } from "src/types/common";

const TaskTypeIcon = {
    [TaskGroup.AnswerQuestionTask]: <UploadIcon />,
    [TaskGroup.ApproveAnswerTask]: <CheckMarkIcon />,
    [TaskGroup.ShoeBoxTask]: <FolderWarningIcon />,
    [TaskGroup.AssignSigneeTask]: <AssignSigneeIcon />,
    [TaskGroup.SignTask]: <FileEditIcon />,
    [TaskGroup.FillTask]: <FileEditIcon />,
}

type Props = {
    type: ValueOf<typeof TaskGroup>
}

export const TaskGroupIcon = (props: Props) => {
    const { type } = props;
    return TaskTypeIcon[type] ?? null;
}