import type { FC, ReactNode } from 'react';
import { Features } from 'src/backend';
import { userSelector } from 'src/slices/view';
import { useSelector } from 'src/store';

interface UserFeatureGuardProps {
    feature?: Features;
    children: ReactNode;
}

export const UserFeatureGuard: FC<UserFeatureGuardProps> = (props) => {
    const { children, feature } = props;
    const user = useSelector(userSelector);

    if (feature && !user?.features?.includes(feature)) {
        return null;
    }

    return <>{children}</>;
};

