import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import type { ListItemProps } from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { UrlObject as Url } from 'url';

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  disabled?: boolean;
  path?: string | Url;
  title: string;
  tooltip?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    disabled,
    open: openProp,
    path,
    tooltip,
    title,
    sx,
    onClick,
    ...other
  } = props;

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          mb: 0.5,
          py: 0,
          px: 2,

        }}
        {...other}
      >
        <Button
          disableRipple
          startIcon={icon}
          data-testid="sidebar-item-branch"
          sx={{
            color: active ? 'secondary.main' : 'neutral.300',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: 1,
            height: '45px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.08)'
            },
            '& .MuiButton-startIcon': {
              color: active ? 'white' : 'primary.light'
            },
            '& .MuiButton-endIcon': {
              color: 'neutral.400'
            }
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        {children}
      </ListItem>
    );
  }

  const content = (<Button
    component={path ? "a" : "button"}
    onClick={onClick}
    onContextMenu={(event) => {
      onClick && onClick(event);
    }}
    disabled={disabled}
    startIcon={icon}
    endIcon={chip}
    disableRipple
    sx={{
      backgroundColor: active && !disabled && 'white',
      borderRadius: path ? '5px' : '5px 0 0 5px',
      color: active && !disabled ? 'primary.dark' : 'neutral.300',
      fontWeight: active && !disabled && 'fontWeightBold',
      justifyContent: 'flex-start',
      pl: `${paddingLeft}px`,
      pr: 1,
      height: '45px',
      textAlign: 'left',
      textTransform: 'none',
      width: '100%',
      '& .MuiButton-startIcon': {
        color: active && !disabled ? 'primary.dark' : 'neutral.200'
      },
      '&:hover': {
        backgroundColor: active && !disabled ? 'white' : 'rgba(255,255,255, 0.08)'
      },
      opacity: disabled ? .2 : 1,
      '&.Mui-disabled': {
        color: 'white'
      }
    }}
  >
    <Box sx={{ flexGrow: 1 }}>
      {title}
    </Box>
    {info}
  </Button>)
  // Leaf

  const item = path ? <NextLink href={path} passHref>{content}</NextLink> : content

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        pl: 2,
        pr: path ? 2 : 0,
        ...(sx ? sx : {})
      }}
      {...other}
    >
      {tooltip ? <Tooltip
        title={title}
        disableInteractive
        arrow
        placement='top-start'>
        <div>{item}</div>
      </Tooltip> : item}
    </ListItem>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string.isRequired
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false
};
