import { AccountBalance as BankIcon, AccountTree as AccountTreeIcon, Book, Home as HomeIcon } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LabelIcon from '@mui/icons-material/Label';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { type Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Badge from 'components/dist/atoms/Badge';
import Icon from 'components/dist/atoms/Icon';
import Image from 'next/image';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FC, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { LoanPhaseCategoryType, ViewType, WorkflowEntity } from 'src/backend';
import { LoanStageCategory, RoleType } from 'src/constants/loan';
import { LENDER_VIEW, PRINCIPAL_VIEW } from 'src/constants/person';
import { PageLabel, Route } from 'src/constants/ui';
import { SettingsContext } from 'src/contexts/settings-context';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useUser } from 'src/hooks/use-user';
import { Archive } from 'src/icons/archive';
import { Cog } from 'src/icons/cog';
import { Coin } from 'src/icons/coin';
import { useGetMyWorkFlowsQuery } from 'src/services/workFlowApi';
import { contextLenderNameSelector } from 'src/slices/loan';
import { selectLoansCountByPhaseCategory } from 'src/slices/loan-manager';
import { hiddenPhasesSelector } from 'src/slices/ui';
import { applicantSelector, viewTypeSelector } from 'src/slices/view';
import { useSelector } from 'src/store';
import { Loan } from 'src/types/loan';
import { getAssetPath } from 'src/utils/url/get-asset-path';

import { CalendarIcon } from "../../icons/calendar-icon";
import { Scrollbar } from '../scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';

interface DashboardSidebarProps {
  onClose: () => void;
  open: boolean;
}

interface Item {
  title: string;
  activeLoan?: boolean;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
}

interface Section {
  title: string;
  items: Item[];
}
const homeSectionItem = {
  phaseCategory: null,
  title: PageLabel.Origination,
  path: Route.HOME,
  icon: <HomeIcon />,

}

const onboardingSectionItem = {
  phaseCategory: null,
  title: PageLabel.Onboarding,
  path: Route.HOME,
  icon: <HomeIcon />,

}

const originationSectionItem = {
  phaseCategory: null,
  title: PageLabel.Origination,
  path: Route.LOANS_ORIGINATION_HOME,
  icon: <Coin />,
}

const lenderSections = [
  {
    title: PageLabel.Home,
    items: [
      {
        phaseCategory: LoanStageCategory.LEAD,
        title: PageLabel.Leads,
        path: Route.LOANS_LEAD_HOME,
        icon: <FilterAltIcon />,
      },
      homeSectionItem,
      {
        phaseCategory: LoanStageCategory.PORTFOLIO,
        title: PageLabel.Portfolio,
        path: Route.LOANS_PORTFOLIO_HOME,
        icon: <Coin />,
      },
      {
        phaseCategory: LoanStageCategory.ARCHIVE,
        title: PageLabel.Archive,
        path: Route.ARCHIVE_HOME,
        icon: <Archive />,
      },
      {
        title: 'Onboarding',
        path: Route.ADMIN_PAGE,
        icon: <Cog fontSize="medium" />,
        feature: 'TEMPLATES'
      },
      {
        title: 'V2 Templates',
        path: Route.LIST_V2_TEMPLATE,
        icon: <AccountTreeIcon fontSize="medium" />,
        feature: 'TEMPLATES'
      },
      {
        title: PageLabel.Labels,
        path: Route.ADMIN_LABELS,
        icon: <LabelIcon fontSize="medium" />,
        feature: 'TEMPLATES'
      },
      {
        title: PageLabel.LearningCenter,
        path: Route.ADMIN_LEARNING_CENTER,
        icon: <Book fontSize="medium" />,
        feature: 'TEMPLATES'
      },
      {
        title: PageLabel.Maintenance,
        path: Route.ADMIN_MAINTENANCE,
        icon: <CalendarIcon fontSize="medium" />,
        feature: 'TEMPLATES'
      },
    ]
  }
];


const borrowerSections = {
  title: PageLabel.Origination,
  items: [
    {
      phaseCategory: LoanStageCategory.LEAD,
      title: PageLabel.Leads,
      path: Route.LOANS_LEAD_HOME,
      icon: <FilterAltIcon />,
    },
    homeSectionItem,
    originationSectionItem,
    {
      phaseCategory: LoanStageCategory.PORTFOLIO,
      title: PageLabel.Portfolio,
      path: Route.LOANS_PORTFOLIO_HOME,
      icon: <Coin />,
    }, {
      phaseCategory: LoanStageCategory.ARCHIVE,
      title: PageLabel.Archive,
      path: Route.ARCHIVE_HOME,
      icon: <Archive />,
    },
  ]
};

interface GetDashboardSectionsProps {
  canSeeInactivePhases: boolean,
  loansCountByPhaseCategory: Record<LoanPhaseCategoryType, boolean>,
  loan: Loan,
  viewType: ViewType,
  hiddenPhases: string[],
  workFlows: WorkflowEntity[],
  onBoardingEnabled: boolean
}

export const getDashboardSections = (args: GetDashboardSectionsProps): Section[] => {
  const items = [];
  if (args.viewType === LENDER_VIEW) {
    items.push(
      ...lenderSections.reduce((acc, section) => {
        const items = section.items
          .filter(item => !args.hiddenPhases?.includes(item.phaseCategory))
          .filter(item => args.canSeeInactivePhases ||
            !item.phaseCategory ||
            (item.phaseCategory && args.loansCountByPhaseCategory[item.phaseCategory]));

        acc.push({
          ...section,
          items: [
            ...(args.workFlows?.length ? [{
              title: PageLabel.Workflows,
              path: Route.WORKFLOWS,
              items: [],
              icon: <Icon name="Network" className='rotate-180' width={20} height={20} strokeWidth={1.5} />,
              info: <Badge className='w-5 h-5 bg-yellow-60 text-black-primary justify-center font-normal'>{args.workFlows.length}</Badge>
            }] : []),
            ...items
          ]
        });
        return acc;
      }, []));
  } else if (args.viewType === PRINCIPAL_VIEW) {
    items.push({
      ...borrowerSections,
      items: [
        ...(args.workFlows?.length ? [{
          title: PageLabel.Workflows,
          path: Route.WORKFLOWS,
          items: [],
          icon: <Icon name="Network" className='rotate-180' width={20} height={20} strokeWidth={1.5} />,
          info: <Badge className='w-5 h-5 bg-yellow-60 text-black-primary justify-center font-normal'>{args.workFlows.length}</Badge>
        }] : []),
        ...(args.onBoardingEnabled ? [onboardingSectionItem] : []),
        ...borrowerSections.items
          .filter(item => !item.phaseCategory ||
            (item.phaseCategory && args.loansCountByPhaseCategory[item.phaseCategory]))
          // hide origination if onboarding is not enabled
          .filter(item => (args.onBoardingEnabled && item.path === Route.LOANS_ORIGINATION_HOME) ||
            item.path !== Route.LOANS_ORIGINATION_HOME)
          // show home if onboarding is enabled
          .filter(item => (!args.onBoardingEnabled && item.path === Route.HOME) ||
            item.path !== Route.HOME)
      ]
    });
  }
  return items.map(item => ({
    ...item,
    items: item.items.map(childItem => {
      return ({
        ...childItem,
        activeLoan: childItem.title.toLowerCase().startsWith(args.loan?.loanPhase?.category.substring(0, 4).toLowerCase())
      })
    }),
  }))
};

export const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onClose, open } = props;
  const router = useRouter();
  const { loan } = useActiveLoan();
  const { user } = useUser();
  const viewType = useSelector(viewTypeSelector);
  const { settings } = useContext(SettingsContext);
  const person = useSelector(applicantSelector);
  const loansCountByPhaseCategory = useSelector(selectLoansCountByPhaseCategory);
  const openSidebar = useSelector((state) => state.ui.openSidebar);
  const lenderName = useSelector(contextLenderNameSelector);
  const hiddenPhases = useSelector(hiddenPhasesSelector);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  const workFlowQuery = useGetMyWorkFlowsQuery(null, {
    pollingInterval: 5000
  });
  const canSeeInactivePhases = user?.roleDefault === RoleType.LeadLender || user?.roleDefault === RoleType.Manager;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sections = useMemo(() => getDashboardSections({
    loansCountByPhaseCategory,
    workFlows: workFlowQuery.data,
    canSeeInactivePhases,
    loan,
    viewType,
    hiddenPhases,
    onBoardingEnabled: person?.onboardNeeded
  }),
    [loansCountByPhaseCategory, workFlowQuery.data, loan, viewType, hiddenPhases, person?.onboardNeeded, canSeeInactivePhases]);

  const handlePathChange = useCallback(() => {
    if (!router.isReady) {
      return;
    }

    if (open && !lgUp) {
      onClose?.();
    }
  }, [lgUp, onClose, open, router]);

  useEffect(() => {
    const routeChangeStart = () => {
      handlePathChange();
    };
    router.events?.on("routeChangeStart", routeChangeStart);
    return () => {
      router.events?.off("routeChangeStart", routeChangeStart);
    };
  },
    [handlePathChange, router.events]
  );

  useEffect(() => {
    handlePathChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSidebar]);

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          width: open ? '100%' : '56px',
          mt: 1,
          textAlign: 'center',
          alignSelf: 'flex-end',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          minHeight: '56px',
          px: 1
        }} >
          <Typography sx={{ fontWeight: 'bold' }}>
            {open ? lenderName : <Tooltip title={lenderName} disableInteractive arrow placement='right'>
              <BankIcon />
            </Tooltip>}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {sections.map((section) => (
            <DashboardSidebarSection
              key={section.title}
              sidebarOpen={open || !lgUp}
              path={router.asPath}
              {...section}
            />
          ))}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: open ? 'center' : 'flex-end',
          justifyContent: open ? 'center' : 'flex-end',
          px: 1,
          pb: 4
        }}>
          {open ? <>
            <Typography variant="body2" color="white">
              Powered by
            </Typography>
            <Image
              src={getAssetPath('static/232px_DarkMode.png')}
              alt="mysherpas"
              width='116'
              height='25'
              unoptimized
            />
            <Typography variant="caption" color="white" component='div'>
              {settings.version}
            </Typography>
          </> : <Image
            src={getAssetPath('static/232px_DarkModeSmall.png')}
            alt="mysherpas"
            width='41'
            height='25'
            unoptimized
          />}
        </Box>

      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        data-testid="dashboard-sidebar-drawer"
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: 0,
            color: '#FFFFFF',
            width: 200,
            marginLeft: {
              lg: -18,
            },
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            ...(open && {
              marginLeft: {
                lg: 0
              },
              transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
              })
            })
          })
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 200
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
