import { j as m } from "./jsx-runtime-944c88e2.js";
import { a as c } from "./index-bf9f2594.js";
import { c as p } from "./index-31854cc2.js";
import * as f from "react";
import { c as u } from "./utils-428888d8.js";
const d = p("font-normal", {
  variants: {
    truncate: {
      true: "truncate"
    },
    size: {
      xs: "text-xs leading-4",
      sm: "text-sm leading-5",
      base: "text-base",
      lg: "text-lg",
      xl: "text-xl",
      xxl: "text-2xl",
      xxxl: "text-3xl"
    },
    weight: {
      thin: "font-thin",
      normal: "font-normal",
      medium: "font-medium",
      bold: "font-bold"
    },
    variant: {
      inherit: "text-inherit",
      primary: "text-black-primary",
      blue: "text-blue-100",
      secondary: "text-gray-secondary",
      white: "text-white",
      success: "text-green-flowkit",
      warning: "text-yellow-60",
      purple: "text-purple-50",
      destructive: "text-destructive",
      "black-primary": "text-black-primary"
    }
  },
  defaultVariants: {
    truncate: !1,
    size: "base",
    weight: "normal",
    variant: "primary"
  }
}), w = f.forwardRef(
  ({
    className: t,
    size: e,
    weight: r,
    variant: a,
    as: i,
    asChild: x,
    truncate: n,
    ...s
  }, o) => {
    const l = x ? c : i || "p";
    return /* @__PURE__ */ m.jsx(
      l,
      {
        className: u(d({ truncate: n, size: e, weight: r, variant: a, className: t })),
        ref: o,
        ...s
      }
    );
  }
);
export {
  w as default
};
