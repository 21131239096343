import { useRef as s, useEffect as a, useMemo as b, useCallback as h, useState as v } from "react";
function R(e, c, { checkForDefaultPrevented: o = !0 } = {}) {
  return function(n) {
    if (e == null || e(n), o === !1 || !n.defaultPrevented)
      return c == null ? void 0 : c(n);
  };
}
function d(e) {
  const c = s(e);
  return a(() => {
    c.current = e;
  }), b(
    () => (...o) => {
      var t;
      return (t = c.current) === null || t === void 0 ? void 0 : t.call(c, ...o);
    },
    []
  );
}
function k({ prop: e, defaultProp: c, onChange: o = () => {
} }) {
  const [t, n] = P({
    defaultProp: c,
    onChange: o
  }), r = e !== void 0, i = r ? e : t, f = d(o), $ = h((u) => {
    if (r) {
      const l = typeof u == "function" ? u(e) : u;
      l !== e && f(l);
    } else
      n(u);
  }, [
    r,
    e,
    n,
    f
  ]);
  return [
    i,
    $
  ];
}
function P({ defaultProp: e, onChange: c }) {
  const o = v(e), [t] = o, n = s(t), r = d(c);
  return a(() => {
    n.current !== t && (r(t), n.current = t);
  }, [
    t,
    n,
    r
  ]), o;
}
export {
  d as $,
  R as a,
  k as b
};
