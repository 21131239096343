import { j as a } from "./jsx-runtime-944c88e2.js";
import { c as n } from "./index-31854cc2.js";
import { c as i } from "./utils-428888d8.js";
import "react";
const s = n(
  "inline-flex items-center border rounded-full px-2.5 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-primary hover:bg-primary/80 border-transparent text-primary-foreground",
        secondary: "bg-secondary hover:bg-secondary/80 border-transparent text-secondary-foreground",
        destructive: "bg-badge-destructive hover:bg-badge-destructive/80 border-badge-destructive text-badge-destructive-foreground",
        outline: "text-foreground",
        white: "bg-white border-white text-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);
function d({ className: e, variant: t, children: r, ...o }) {
  return r ? /* @__PURE__ */ a.jsx("div", { className: i(s({ variant: t }), e), ...o, children: r }) : null;
}
d.displayName = "Badge";
export {
  d as default
};
