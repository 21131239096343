import * as t from "react";
import { a as e } from "./index-61f4edff.js";
const r = t["useId".toString()] || (() => {
});
let n = 0;
function s(a) {
  const [o, f] = t.useState(r());
  return e(() => {
    a || f(
      ($) => $ ?? String(n++)
    );
  }, [
    a
  ]), a || (o ? `radix-${o}` : "");
}
export {
  s as $
};
