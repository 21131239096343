import { useRouter } from "next/router"
import { useEffect } from "react";
import { getLoan, loanSelector } from "src/slices/loan";
import { useDispatch, useSelector } from "src/store";
import { Loan } from "src/types/loan";

interface ReturnValue {
    loan: undefined | Loan;
}

export const useActiveLoan = (): ReturnValue => {
    const { query: { loanId } } = useRouter();
    const dispatch = useDispatch();
    const loan: undefined | Loan = useSelector(loanSelector(String(loanId)));

    useEffect(() => {
        if (loanId && !loan) {
            dispatch(getLoan(String(loanId)));
        }
    }, [dispatch, loan, loanId]);
    
    return {
        loan
    };
}