import * as React from "react"

export const FolderWarning = (props) => (
    <svg
        width={22}
        height={19}
        fill="none"
        data-testid="FolderWarning"
        viewBox="0 0 22 19"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 1v4m0 4.01.01-.011M21 5v12.4a.6.6 0 0 1-.6.6H1.6a.6.6 0 0 1-.6-.6V9"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 5h-1.278a.6.6 0 0 1-.39-.144L8.168 2.144A.6.6 0 0 0 7.778 2H1.6a.6.6 0 0 0-.6.6V9h12"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);