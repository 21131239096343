import * as React from "react"

export const FileEdit = (props) => (
    <svg
        width={19}
        height={22}
        fill="none"
        data-testid="FileEdit"
        viewBox="0 0 19 22"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 11V4.749a.6.6 0 0 0-.176-.425l-3.148-3.148A.6.6 0 0 0 13.252 1H1.6a.6.6 0 0 0-.6.6v18.8a.6.6 0 0 0 .6.6H8M5 9h8M5 5h4m-4 8h3m6.954 2.94 1-1a1.119 1.119 0 0 1 1.586 0 1.121 1.121 0 0 1 0 1.585l-1 1m-1.586-1.586-2.991 2.991a1 1 0 0 0-.28.553l-.244 1.557 1.557-.243a1 1 0 0 0 .553-.28l2.99-2.992m-1.585-1.586 1.586 1.586"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 1v3.4a.6.6 0 0 0 .6.6H17"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);