import { Children as p, cloneElement as v, useState as M, useRef as N, useEffect as O, useCallback as T, useReducer as E } from "react";
import { flushSync as h } from "react-dom";
import { $ as U } from "./index-bf9f2594.js";
import { a as $ } from "./index-61f4edff.js";
function I(n, t) {
  return E((r, o) => {
    const i = t[r][o];
    return i ?? r;
  }, n);
}
const P = (n) => {
  const { present: t, children: r } = n, o = S(t), i = typeof r == "function" ? r({
    present: o.isPresent
  }) : p.only(r), c = U(o.ref, i.ref);
  return typeof r == "function" || o.isPresent ? /* @__PURE__ */ v(i, {
    ref: c
  }) : null;
};
P.displayName = "Presence";
function S(n) {
  const [t, r] = M(), o = N({}), i = N(n), c = N("none"), l = n ? "mounted" : "unmounted", [f, a] = I(l, {
    mounted: {
      UNMOUNT: "unmounted",
      ANIMATION_OUT: "unmountSuspended"
    },
    unmountSuspended: {
      MOUNT: "mounted",
      ANIMATION_END: "unmounted"
    },
    unmounted: {
      MOUNT: "mounted"
    }
  });
  return O(() => {
    const e = d(o.current);
    c.current = f === "mounted" ? e : "none";
  }, [
    f
  ]), $(() => {
    const e = o.current, s = i.current;
    if (s !== n) {
      const A = c.current, m = d(e);
      n ? a("MOUNT") : m === "none" || (e == null ? void 0 : e.display) === "none" ? a("UNMOUNT") : a(s && A !== m ? "ANIMATION_OUT" : "UNMOUNT"), i.current = n;
    }
  }, [
    n,
    a
  ]), $(() => {
    if (t) {
      const e = (u) => {
        const m = d(o.current).includes(u.animationName);
        u.target === t && m && h(
          () => a("ANIMATION_END")
        );
      }, s = (u) => {
        u.target === t && (c.current = d(o.current));
      };
      return t.addEventListener("animationstart", s), t.addEventListener("animationcancel", e), t.addEventListener("animationend", e), () => {
        t.removeEventListener("animationstart", s), t.removeEventListener("animationcancel", e), t.removeEventListener("animationend", e);
      };
    } else
      a("ANIMATION_END");
  }, [
    t,
    a
  ]), {
    isPresent: [
      "mounted",
      "unmountSuspended"
    ].includes(f),
    ref: T((e) => {
      e && (o.current = getComputedStyle(e)), r(e);
    }, [])
  };
}
function d(n) {
  return (n == null ? void 0 : n.animationName) || "none";
}
export {
  P as $
};
