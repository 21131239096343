import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { ANONYMOUS_VIEW } from 'src/constants/person';
import { Route } from 'src/constants/ui';
import { useDragOver } from 'src/hooks/use-drag-over';
import { useMounted } from 'src/hooks/use-mounted';
import { useOpenDrawer } from 'src/hooks/use-open-drawer';
import { useQueryParamToast } from 'src/hooks/useQueryParamToast';
import { Error500 } from 'src/icons/error500_light';
import { fetch3rdPartyLicenseKeys } from 'src/slices/license-keys';
import { getJavascriptConstants } from 'src/slices/lists';
import { selectConnectionError, selectDrawerOpen, toggleDrawer } from 'src/slices/ui';
import { getView, viewTypeSelector } from 'src/slices/view';
import { useDispatch, useSelector } from 'src/store';
import { UrlObject as Url } from 'url';

import { DowntimeAlert } from '../maintenance/downtime-alert';
import { SplashScreen } from '../splash-screen';
import { TimezoneChecker } from '../user/timezone-checker';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';

interface DashboardLayoutProps {
  children?: ReactNode;
  noSideBar?: boolean;
  backToLink?: string | Url;
  noSearch?: boolean;
}

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flex: '1 1 auto',
    backgroundColor: theme.palette.neutral[200],
    paddingTop: 54
  })
);

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const { children } = props;
  useQueryParamToast();
  useDragOver();
  useOpenDrawer();
  // hooks
  const dispatch = useDispatch();
  const isMounted = useMounted();
  const { asPath, pathname, push, isReady, query } = useRouter();
  const theme = useTheme();
  // redux state selectors
  const drawerOpen = useSelector(selectDrawerOpen);
  const connectionError = useSelector(selectConnectionError);
  const viewType = useSelector(viewTypeSelector)

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer(!drawerOpen));
  }

  useEffect(() => {
    if (isMounted() && viewType === ANONYMOUS_VIEW && isReady) {
      push({ pathname: Route.ANONYMOUS_HOME, query });
    }
  }, [viewType, isMounted, push, isReady, query]);

  useEffect(() => {
    if (isMounted() && isReady) {
      if (!([
        Route.HOME,
        Route.LENDER_HOME,
        Route.PRINCIPAL_HOME
      ] as string[]).includes(asPath.split('?')?.[0]) && pathname === Route.HOME) {
        push(asPath);
      }
    }
  }, [isReady, asPath, push, isMounted, pathname]);

  useEffect(() => {
    dispatch(getJavascriptConstants());
    // get 3rd party license keys
    dispatch(fetch3rdPartyLicenseKeys());
    dispatch(getView({ viewType }));
  }, [dispatch, viewType]);

  if (!viewType) {
    return <SplashScreen />
  }

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            paddingLeft: {
              lg: props.noSideBar ? '0px' : '56px',
            },
            transition: theme.transitions.create('padding', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            ...(drawerOpen && {
              paddingLeft: {
                lg: props.noSideBar ? '0px' : '200px',
              },
              transition: theme.transitions.create('padding', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
              })
            }),
          }}
        >
          <DowntimeAlert />
          {children}
        </Box>
      </DashboardLayoutRoot>
      <TimezoneChecker />
      <Dialog
        open={!!connectionError}
        fullWidth
        maxWidth="sm">
        <Box sx={{
          flexDirection: 'column',
          padding: 5,
          display: 'flex',
          backgroundColor: 'primary.dark',
        }} >
          <Typography variant="h4" color='white'>
            <CircularProgress size={25} />  Connection Error
          </Typography>
          <Typography variant="body1" color='white'>
            Sorry we&apos;re having trouble connecting with you. Check that your Internet is working and seems stable. We&apos;ll keep trying here, but if you continue having issues, check back in a bit.
          </Typography>
          <Box sx={{
            mt: 5,
            display: 'inline-block',
            position: 'relative',
            width: '100%',
            paddingBottom: '90%',
            verticalAlign: 'middle',
            overflow: 'hidden',
          }}>
            <Error500 style={{
              display: 'inline-block',
              position: 'absolute',
              top: 0,
              left: 0
            }} />
          </Box>
        </Box>
      </Dialog>
      <DashboardNavbar
        sidebarOpen={drawerOpen}
        noSideBar={props.noSideBar}
        noSearch={props.noSearch}
        backToLink={props.backToLink ? props.backToLink : undefined}
        onOpenSidebar={props.noSideBar ? undefined : handleDrawerToggle} />
      {!props.noSideBar && <DashboardSidebar
        onClose={handleDrawerToggle}
        open={drawerOpen}
      />}
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

DashboardLayout.displayName = 'DashboardLayout';