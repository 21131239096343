import * as React from "react"

export const Chat = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    data-testid="Chat"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M4.832 9h8.333M4.832 5.665h5"
      stroke="currentColor"
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 15.908V3.167A1.667 1.667 0 0 1 3.167 1.5h11.666A1.666 1.666 0 0 1 16.5 3.167V11.5a1.666 1.666 0 0 1-1.667 1.667H5.634a1.666 1.666 0 0 0-1.3.625L2.39 16.22a.5.5 0 0 1-.891-.312Z"
      stroke="currentColor"
      strokeWidth={1}
    />
  </svg>
)