import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { useRouter } from 'next/router';
import { LoanViewType, Role } from 'src/backend';
import { DashboardSidebarItem } from 'src/components/dashboard/dashboard-sidebar-item';
import { LOAN_TABS } from 'src/components/loans/tabs/loan-tabs-nav';
import { TaskGroupIcon } from 'src/components/tasks/task-group-icon';
import { RoleType } from 'src/constants/loan';
import { LoanSidebar, Route, TaskGroup } from 'src/constants/ui';
import { Permission } from 'src/constants/user';
import { useLoanTasks } from 'src/hooks/use-loan-tasks';
import { useUser } from 'src/hooks/use-user';
import { getString } from 'src/i18n/labels';
import { Chat } from 'src/icons/chat';
import { Folder } from 'src/icons/folder';
import { ViewGridIcon } from 'src/icons/view-grid-icon';
import { contextLoanUnreadMessagesCountSelector } from 'src/slices/chat';
import { loanSelector, loanViewTypeSelector } from 'src/slices/loan';
import { setExpandedAccordionUserId } from 'src/slices/task';
import { setOpenLoanSidebar, uiSelector } from 'src/slices/ui';
import { useDispatch, useSelector } from 'src/store';
import { TLoanSidebar } from 'src/types/ui';
import { roleCan } from 'src/utils/user/role-can';

interface Props {
    sidebarOpen?: boolean,
}

export const LoanToolBox = (props: Props) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const loan = useSelector(loanSelector(String(router.query.loanId)));
    const unreadMessagesCount = useSelector(contextLoanUnreadMessagesCountSelector);
    const loanViewType = useSelector(loanViewTypeSelector(String(router.query.loanId)));
    const { openSidebar } = useSelector(uiSelector);
    const { userRole } = useUser({
        loan
    });

    const {
        totalUploadFileTasks,
        totalApproveFileTasks,
        totalAssignSigneeTasks,
        totalShoeboxTasks,
        totalFillDocumentTasks } = useLoanTasks({ loanId: String(router.query.loanId) });

    const handleToggleSidebar = (sidebar: TLoanSidebar) => {
        const isOpen = openSidebar === sidebar;
        dispatch(setExpandedAccordionUserId(null));
        dispatch(setOpenLoanSidebar(isOpen ? null : sidebar));
        if ((router.query.tab !== LOAN_TABS.PACKAGE && !isOpen && sidebar !== LoanSidebar.CHAT) || sidebar === LoanSidebar.TASKS_LIST_APPROVE) {
            router.push({
                pathname: Route.SINGLE_LOAN,
                query: {
                    ...router.query,
                    loanId: router.query.loanId,
                    tab: LOAN_TABS.PACKAGE,
                }
            })
        }
    }

    const handlePackageLinkClick = () => {
        dispatch(setOpenLoanSidebar(null));
        let finalLoanViewType = LoanViewType.CONVENTIONAL;
        if (loanViewType) {
            finalLoanViewType = loanViewType;
        } else if (loan?.template?.loanViewType) {
            finalLoanViewType = loan.template.loanViewType;
        }
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                ...router.query,
                loanId: router.query.loanId,
                tab: LOAN_TABS.PACKAGE,
                loanViewType: finalLoanViewType,
            }
        });
    }

    const handleOverviewLinkClick = () => {
        if (openSidebar !== LoanSidebar.CHAT) {
            dispatch(setOpenLoanSidebar(null));
        }
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                ...router.query,
                loanId: router.query.loanId,
                tab: LOAN_TABS.DETAILS
            }
        })
    };

    const itemsList = getToolsList({
        openSidebar,
        totalMessages: unreadMessagesCount,
        totalUpload: totalUploadFileTasks,
        totalFill: totalFillDocumentTasks,
        totalAccept: totalApproveFileTasks,
        totalAssign: totalAssignSigneeTasks,
        totalShoeBox: totalShoeboxTasks,
        tab: String(router.query.tab),
        onToggle: handleToggleSidebar,
        onOverviewClick: handleOverviewLinkClick,
        onPackageClick: handlePackageLinkClick,
        isUserLeadLender: (userRole === RoleType.LeadLender || userRole === RoleType.Manager),
        userRole,
    });

    return <List
        disablePadding
        sx={{
            backgroundColor: 'primary.main',
            transition: theme => theme.transitions.create('width', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            mb: 1,
            width: '100%',
            '& .MuiListItem-root': {
                transition: theme => theme.transitions.create(['width', 'padding'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
            },
            ...(!props.sidebarOpen && {
                width: '76px',
                transition: theme => theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                }),
                '& .MuiListItem-root': {
                    transition: theme => theme.transitions.create(['width', 'padding'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen
                    }),
                }
            })
        }}>
        <Divider sx={{
            borderTopStyle: 'solid',
            borderTopColor: 'white',
            borderTopWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'primary.dark',
            borderBottomWidth: '8px',
            mb: 1
        }} />
        {itemsList.map(item => {
            const listItem = <DashboardSidebarItem
                key={item.id}
                tooltip={!props.sidebarOpen}
                disabled={!router.query.loanId}
                open={false}
                active={item.active}
                icon={item.icon}
                title={item.title}
                sx={{ pl: props.sidebarOpen ? item.ml : 2, width: 200 }}
                onClick={item.onClick}
                depth={0} />

            if (item.hidden) {
                return null;
            }

            return listItem;
        })
        }
        <Divider sx={{ mt: 1, borderTopStyle: 'solid', borderTopColor: 'primary.dark', borderTopWidth: '8px' }} />
    </List>
}

interface ToolsListProps {
    openSidebar: TLoanSidebar,
    totalAccept: number,
    totalUpload: number,
    totalAssign: number,
    totalFill: number,
    totalMessages: number,
    totalShoeBox: number
    onToggle: (sidebar: TLoanSidebar) => void,
    onPackageClick: () => void,
    onOverviewClick: () => void,
    isUserLeadLender: boolean,
    tab: string,
    userRole: Role
}

const getToolsList = ({
    openSidebar,
    totalAccept,
    totalUpload,
    totalMessages,
    onOverviewClick,
    onPackageClick,
    totalAssign,
    totalFill,
    totalShoeBox,
    tab,
    onToggle,
    isUserLeadLender,
    userRole
}: ToolsListProps) => {
    return [
        {
            id: 0,
            title: 'Overview',
            active: tab === LOAN_TABS.DETAILS,
            icon: <ViewGridIcon />,
            onClick: onOverviewClick,
            ml: 2
        },
        {
            id: 1,
            title: 'Package',
            active: tab === LOAN_TABS.PACKAGE,
            icon: <Folder />,
            onClick: onPackageClick,
            ml: 2
        },
        {
            id: 2,
            title: 'Messages',
            active: openSidebar === LoanSidebar.CHAT,
            icon: <Badge badgeContent={totalMessages} color='error' anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}>
                <Chat />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.CHAT),
            ml: 3,
        },
        {
            id: 3,
            title: 'Shoebox',
            active: openSidebar === LoanSidebar.TASKS_LIST_SHOE_BOX,
            icon: <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }} color='error'
                badgeContent={totalShoeBox}>
                <TaskGroupIcon type={TaskGroup.ShoeBoxTask} />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_SHOE_BOX),
            ml: 3,
            hidden: !userRole || !roleCan(userRole, Permission.viewShoeBoxItems)
        },
        {
            id: 4,
            title: 'Upload',
            active: openSidebar === LoanSidebar.TASKS_LIST_UPLOAD,
            icon: <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }} color='error'
                badgeContent={totalUpload}>
                <TaskGroupIcon type={TaskGroup.AnswerQuestionTask} />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_UPLOAD),
            ml: 3,
            hidden: (!isUserLeadLender && totalUpload === 0)
        },
        {
            id: 5,
            title: getString('fillAndSign'),
            active: openSidebar === LoanSidebar.TASKS_LIST_FILL,
            icon: <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }} color='error'
                badgeContent={totalFill}>
                <TaskGroupIcon type={TaskGroup.FillTask} />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_FILL),
            ml: 3,
            hidden: (!isUserLeadLender && totalFill === 0)
        },
        {
            id: 6,
            title: 'Assign Signee',
            active: openSidebar === LoanSidebar.TASKS_LIST_ASSIGN_SIGNEE,
            icon: <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }} color='error'
                badgeContent={totalAssign}>
                <TaskGroupIcon type={TaskGroup.AssignSigneeTask} />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_ASSIGN_SIGNEE),
            ml: 3,
            hidden: !isUserLeadLender
        },
        {
            id: 7,
            title: getString('accept'),
            active: openSidebar === LoanSidebar.TASKS_LIST_APPROVE,
            icon: <Badge anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }} color='error'
                badgeContent={totalAccept}>
                <TaskGroupIcon type={TaskGroup.ApproveAnswerTask} />
            </Badge>,
            onClick: (): void => onToggle(LoanSidebar.TASKS_LIST_APPROVE),
            ml: 3,
            hidden: !roleCan(userRole, Permission.AcceptDocuments) || (!isUserLeadLender && totalAccept === 0)
        }
    ]
}