import { styled } from "@mui/material/styles";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2.5),
}));

const Description = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
    fontWeight: 400,
}));

const Actions = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    flex: 1,
    width: '100%',
    padding: theme.spacing(0, 8),
    '& > button': {
        flexGrow: 1,
        color: themeV2.colors.black.neutral,
        borderColor: themeV2.neutral.grey[80],
        fontWeight: 500,
    }
}));

export const TimezoneCheckerStyles = {
    Container,
    Description,
    Actions
}