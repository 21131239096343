import * as React from "react"

export const Archive = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    data-testid="Archive"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#aArchive)" stroke="currentColor" strokeWidth={1.5}>
      <path
        d="M5.836 5h8.333M5.836 7.5h8.333m-6.666 6.667h5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.501 10h-.333a.5.5 0 0 0-.5.5v7.333a.5.5 0 0 0 .5.5h15.667a.5.5 0 0 0 .5-.5v-7.334a.5.5 0 0 0-.5-.5H17.5m-15 0V2.166a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5v7.833m-15 0h15" />
    </g>
    <defs>
      <clipPath id="aArchive">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)