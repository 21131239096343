import { useCallback as h, forwardRef as $, Children as s, isValidElement as a, createElement as u, cloneElement as m, Fragment as y } from "react";
function f() {
  return f = Object.assign ? Object.assign.bind() : function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = arguments[t];
      for (var r in n)
        Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
    }
    return e;
  }, f.apply(this, arguments);
}
function v(e, t) {
  typeof e == "function" ? e(t) : e != null && (e.current = t);
}
function b(...e) {
  return (t) => e.forEach(
    (n) => v(n, t)
  );
}
function N(...e) {
  return h(b(...e), e);
}
const g = /* @__PURE__ */ $((e, t) => {
  const { children: n, ...r } = e, c = s.toArray(n), o = c.find(C);
  if (o) {
    const l = o.props.children, i = c.map((p) => p === o ? s.count(l) > 1 ? s.only(null) : /* @__PURE__ */ a(l) ? l.props.children : null : p);
    return /* @__PURE__ */ u(d, f({}, r, {
      ref: t
    }), /* @__PURE__ */ a(l) ? /* @__PURE__ */ m(l, void 0, i) : null);
  }
  return /* @__PURE__ */ u(d, f({}, r, {
    ref: t
  }), n);
});
g.displayName = "Slot";
const d = /* @__PURE__ */ $((e, t) => {
  const { children: n, ...r } = e;
  return /* @__PURE__ */ a(n) ? /* @__PURE__ */ m(n, {
    ...E(r, n.props),
    ref: t ? b(t, n.ref) : n.ref
  }) : s.count(n) > 1 ? s.only(null) : null;
});
d.displayName = "SlotClone";
const x = ({ children: e }) => /* @__PURE__ */ u(y, null, e);
function C(e) {
  return /* @__PURE__ */ a(e) && e.type === x;
}
function E(e, t) {
  const n = {
    ...t
  };
  for (const r in t) {
    const c = e[r], o = t[r];
    /^on[A-Z]/.test(r) ? c && o ? n[r] = (...i) => {
      o(...i), c(...i);
    } : c && (n[r] = c) : r === "style" ? n[r] = {
      ...c,
      ...o
    } : r === "className" && (n[r] = [
      c,
      o
    ].filter(Boolean).join(" "));
  }
  return {
    ...e,
    ...n
  };
}
export {
  N as $,
  f as _,
  g as a,
  b,
  x as c
};
