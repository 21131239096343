import { useMemo } from "react";
import { AppUserDTO2, IUser } from "src/backend";
import { RoleType } from "src/constants/loan";
import { PRINCIPAL_VIEW } from "src/constants/person";
import { TaskGroup } from "src/constants/ui";
import { loanSelector } from "src/slices/loan";
import { selectLoanTotalShoeBoxItems } from "src/slices/shoebox";
import { loanTasksSelector, UserTasks } from "src/slices/task";
import { viewTypeSelector } from "src/slices/view";
import { useSelector } from "src/store";
import { Loan } from "src/types/loan";

import { useUser } from "./use-user";

type Props = {
    loanId: string;
}

export const useLoanTasks = ({ loanId }: Props): {
    totalTasks: number;
    totalUploadFileTasks: number;
    totalApproveFileTasks: number;
    totalFillDocumentTasks: number;
    totalAssignSigneeTasks: number;
    totalShoeboxTasks: number;
    totalSignDocumentTasks: number;
    users: AppUserDTO2[],
    loanTasks: Record<string, UserTasks[]>
} => {
    const loan: Loan = useSelector(loanSelector(loanId));
    const viewType = useSelector(viewTypeSelector);
    const loanTasks = useSelector(loanTasksSelector({ loanId }));
    const { user: currentUser, userRole } = useUser({
        loan
    });
    const isLoggedUserLeadLender = useMemo(() => {
        return userRole === RoleType.Manager || loan?.loanRoles?.some(role => role.user.id === currentUser?.id && (role.role === RoleType.LeadLender));
    }, [currentUser.id, loan?.loanRoles, userRole]);

    const users = useMemo(() => {
        return (viewType === PRINCIPAL_VIEW || !isLoggedUserLeadLender) ? [currentUser] : loan?.loanRoles.map(role => role.user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, loan?.loanRoles.length, viewType, isLoggedUserLeadLender]);

    const loanTasksKeys = Object.keys(loanTasks).filter(key => (key === currentUser.id && !isLoggedUserLeadLender) || isLoggedUserLeadLender);
    // count total tasks
    const totalTasks = loanTasksKeys.reduce((acc, key) => {
        return acc + Object.values(loanTasks[key]).reduce((acc2, tasks) => {
            return acc2 + Object.values(tasks).length;
        }, 0);
    }, 0);

    // total answer tasks
    const totalUploadFileTasks = loanTasksKeys.reduce((acc, key) => {
        return acc + Object.keys(loanTasks[key]).filter(taskGroupKey => taskGroupKey === TaskGroup.AnswerQuestionTask).reduce((acc2, taskGroupKey) => {
            return acc2 + loanTasks[key][taskGroupKey].length;
        }, 0);
    }, 0);
    // total answer tasks
    const totalAssignSigneeTasks = loanTasksKeys.reduce((acc, key) => {
        return acc + Object.keys(loanTasks[key]).filter(taskGroupKey => taskGroupKey === TaskGroup.AssignSigneeTask).reduce((acc2, taskGroupKey) => {
            return acc2 + loanTasks[key][taskGroupKey].length;
        }, 0);
    }, 0);
    // total fill tasks
    const totalFillDocumentTasks = loanTasksKeys.reduce((acc, key) => {
        return acc + Object.keys(loanTasks[key]).filter(taskGroupKey => taskGroupKey === TaskGroup.FillTask).reduce((acc2, taskGroupKey) => {
            return acc2 + loanTasks[key][taskGroupKey].length;
        }, 0);
    }, 0);

    // total sign tasks
    const totalSignDocumentTasks = loanTasksKeys.reduce((acc, key) => {
        return acc + Object.keys(loanTasks[key]).filter(taskGroupKey => taskGroupKey === TaskGroup.SignTask).reduce((acc2, taskGroupKey) => {
            return acc2 + loanTasks[key][taskGroupKey].length;
        }, 0);
    }, 0);

    // total shoebox tasks
    const totalShoeboxTasks = useSelector(selectLoanTotalShoeBoxItems(loanId));

    const totalApproveFileTasks = loanTasksKeys.reduce((acc, userIdKey) => {
        const userApproveTaskGroupKeys = Object.keys(loanTasks[userIdKey]).filter(taskGroupKey => taskGroupKey === TaskGroup.ApproveAnswerTask);
        const tasksIds = userApproveTaskGroupKeys.reduce((acc2: string[], taskGroupKey) => {
            return [...acc2, ...loanTasks[userIdKey][taskGroupKey].map(task => task.contextId)];
        }, []);

        return [...acc, ...tasksIds.filter(taskId => acc.indexOf(taskId) === -1)];
    }, []).length;

    return ({
        totalTasks,
        totalShoeboxTasks,
        totalAssignSigneeTasks,
        totalUploadFileTasks,
        totalApproveFileTasks,
        totalFillDocumentTasks,
        totalSignDocumentTasks,
        // ! Fix this type
        // @ts-ignore
        users,
        loanTasks
    });
}