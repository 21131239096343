import * as React from "react"

export const Coin = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        data-testid="Coin"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            clipPath="url(#aArchive)"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M10.001 18.333a8.333 8.333 0 1 0 0-16.667 8.333 8.333 0 1 0 0 16.667Z" />
            <path d="M12.5 7.082c-.57-.57-1.576-.967-2.5-.992m-2.5 6.409c.537.716 1.536 1.125 2.5 1.159m0-7.568c-1.1-.03-2.083.467-2.083 1.825 0 2.5 4.583 1.25 4.583 3.75 0 1.426-1.22 2.039-2.5 1.993m0-7.568V4.582m0 9.076v1.757" />
        </g>
        <defs>
            <clipPath id="aArchive">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)