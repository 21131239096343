import 'simplebar/dist/simplebar.min.css';

import type { SxProps, Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { MutableRefObject } from 'react';
import { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';

interface ScrollbarProps extends SimpleBar.Props {
  ref: MutableRefObject<SimpleBar>;
  sx?: SxProps<Theme>;
}

const ScrollbarRoot = styled(SimpleBar)``;

// eslint-disable-next-line react/display-name
export const Scrollbar = forwardRef<MutableRefObject<SimpleBar>, ScrollbarProps>((props, ref) => {
  return (
    <ScrollbarRoot
      ref={ref}
      {...props}
    />
  );
});
