import Container from "@mui/material/Container";
import { FolderSettings } from 'iconoir-react';
import React from "react";

import { useMaintenanceWindow } from "../../../hooks/use-maintenance-window";
import { DowntimeAlertStyles } from "./downtime-alert.component.styles";

export const DowntimeAlert = () => {

    const { title, message } = useMaintenanceWindow();

    if (!message) return null;

    return <DowntimeAlertStyles.Wrapper>
        <Container maxWidth="desktop">
            <DowntimeAlertStyles.Content>
                <DowntimeAlertStyles.IconWrapper>
                    <FolderSettings fr={"0"} />
                </DowntimeAlertStyles.IconWrapper>
                <DowntimeAlertStyles.Title>
                    {title}
                </DowntimeAlertStyles.Title>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </DowntimeAlertStyles.Content>
        </Container>
    </DowntimeAlertStyles.Wrapper>
};
