import { j as d } from "./jsx-runtime-944c88e2.js";
import { a as c } from "./index-bf9f2594.js";
import { c as u } from "./index-31854cc2.js";
import * as f from "react";
import { c as m } from "./utils-428888d8.js";
const b = u(
  "inline-flex items-center border border-transparent justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background space-x-2",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary",
        text: "text-primary",
        dark: "bg-black-primary text-white hover:bg-black-100"
      },
      size: {
        default: "h-10 py-2 px-4",
        sm: "h-8 px-3 rounded-md",
        lg: "h-11 px-8 rounded-md"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
), l = f.forwardRef(
  ({ className: e, variant: r, size: t, type: o, asChild: n = !1, ...a }, i) => {
    const s = n ? c : "button";
    return /* @__PURE__ */ d.jsx(
      s,
      {
        type: o ?? "button",
        className: m(b({ variant: r, size: t, className: e })),
        ref: i,
        ...a
      }
    );
  }
);
l.displayName = "Button";
export {
  l as default
};
