import * as React from "react"

export const UserPlus = (props) => (
    <svg
        width={24}
        height={18}
        fill="none"
        data-testid="UserPlus"
        viewBox="0 0 24 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 7h3m3 0h-3m0 0V4m0 3v3M1 17v-1a7 7 0 0 1 7-7v0a7 7 0 0 1 7 7v1M8 9a4 4 0 1 0 0-8 4 4 0 0 0 0 8v0Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);