import { createContext as x, useMemo as $, createElement as h, useContext as S, useLayoutEffect as C } from "react";
function P(e, i = []) {
  let n = [];
  function d(u, o) {
    const t = /* @__PURE__ */ x(o), c = n.length;
    n = [
      ...n,
      o
    ];
    function p(a) {
      const { scope: r, children: f, ...l } = a, m = (r == null ? void 0 : r[e][c]) || t, b = $(
        () => l,
        Object.values(l)
      );
      return /* @__PURE__ */ h(m.Provider, {
        value: b
      }, f);
    }
    function v(a, r) {
      const f = (r == null ? void 0 : r[e][c]) || t, l = S(f);
      if (l)
        return l;
      if (o !== void 0)
        return o;
      throw new Error(`\`${a}\` must be used within \`${u}\``);
    }
    return p.displayName = u + "Provider", [
      p,
      v
    ];
  }
  const s = () => {
    const u = n.map((o) => /* @__PURE__ */ x(o));
    return function(t) {
      const c = (t == null ? void 0 : t[e]) || u;
      return $(
        () => ({
          [`__scope${e}`]: {
            ...t,
            [e]: c
          }
        }),
        [
          t,
          c
        ]
      );
    };
  };
  return s.scopeName = e, [
    d,
    _(s, ...i)
  ];
}
function _(...e) {
  const i = e[0];
  if (e.length === 1)
    return i;
  const n = () => {
    const d = e.map(
      (s) => ({
        useScope: s(),
        scopeName: s.scopeName
      })
    );
    return function(u) {
      const o = d.reduce((t, { useScope: c, scopeName: p }) => {
        const a = c(u)[`__scope${p}`];
        return {
          ...t,
          ...a
        };
      }, {});
      return $(
        () => ({
          [`__scope${i.scopeName}`]: o
        }),
        [
          o
        ]
      );
    };
  };
  return n.scopeName = i.scopeName, n;
}
const w = globalThis != null && globalThis.document ? C : () => {
};
export {
  P as $,
  w as a
};
