import { j as y } from "./jsx-runtime-944c88e2.js";
import { $ as w, _ as v, a as ye } from "./index-bf9f2594.js";
import * as M from "react";
import { forwardRef as g, useContext as Oe, useState as B, useEffect as C, createElement as d, useRef as x, createContext as _e, useCallback as oe, Children as Ce, Fragment as Pe } from "react";
import { a as P, $ as A, b as Te } from "./index-5499c00c.js";
import { $ as Fe } from "./index-61f4edff.js";
import { $ as j } from "./index-7ed7ad30.js";
import { $ as T, a as Ne } from "./index-14f22d4f.js";
import { $ as Ie } from "./index-760cd071.js";
import Re from "react-dom";
import { $ as G } from "./index-e1c48dc5.js";
import { $ as we, h as Le, a as Ae } from "./Combination-ba9973a9.js";
import { c as N } from "./utils-428888d8.js";
const H = "dismissableLayer.update", Me = "dismissableLayer.pointerDownOutside", Se = "dismissableLayer.focusOutside";
let Z;
const ke = /* @__PURE__ */ _e({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), Ue = /* @__PURE__ */ g((e, t) => {
  var o;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: s, onPointerDownOutside: c, onFocusOutside: r, onInteractOutside: a, onDismiss: u, ...m } = e, l = Oe(ke), [f, S] = B(null), p = (o = f == null ? void 0 : f.ownerDocument) !== null && o !== void 0 ? o : globalThis == null ? void 0 : globalThis.document, [, k] = B({}), i = w(
    t,
    ($) => S($)
  ), O = Array.from(l.layers), [b] = [
    ...l.layersWithOutsidePointerEventsDisabled
  ].slice(-1), F = O.indexOf(b), E = f ? O.indexOf(f) : -1, h = l.layersWithOutsidePointerEventsDisabled.size > 0, I = E >= F, De = je(($) => {
    const L = $.target, Y = [
      ...l.branches
    ].some(
      (U) => U.contains(L)
    );
    !I || Y || (c == null || c($), a == null || a($), $.defaultPrevented || u == null || u());
  }, p), X = Ke(($) => {
    const L = $.target;
    [
      ...l.branches
    ].some(
      (U) => U.contains(L)
    ) || (r == null || r($), a == null || a($), $.defaultPrevented || u == null || u());
  }, p);
  return Ie(($) => {
    E === l.layers.size - 1 && (s == null || s($), !$.defaultPrevented && u && ($.preventDefault(), u()));
  }, p), C(() => {
    if (f)
      return n && (l.layersWithOutsidePointerEventsDisabled.size === 0 && (Z = p.body.style.pointerEvents, p.body.style.pointerEvents = "none"), l.layersWithOutsidePointerEventsDisabled.add(f)), l.layers.add(f), q(), () => {
        n && l.layersWithOutsidePointerEventsDisabled.size === 1 && (p.body.style.pointerEvents = Z);
      };
  }, [
    f,
    p,
    n,
    l
  ]), C(() => () => {
    f && (l.layers.delete(f), l.layersWithOutsidePointerEventsDisabled.delete(f), q());
  }, [
    f,
    l
  ]), C(() => {
    const $ = () => k({});
    return document.addEventListener(H, $), () => document.removeEventListener(H, $);
  }, []), /* @__PURE__ */ d(T.div, v({}, m, {
    ref: i,
    style: {
      pointerEvents: h ? I ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: P(e.onFocusCapture, X.onFocusCapture),
    onBlurCapture: P(e.onBlurCapture, X.onBlurCapture),
    onPointerDownCapture: P(e.onPointerDownCapture, De.onPointerDownCapture)
  }));
});
function je(e, t = globalThis == null ? void 0 : globalThis.document) {
  const o = A(e), n = x(!1), s = x(() => {
  });
  return C(() => {
    const c = (a) => {
      if (a.target && !n.current) {
        let m = function() {
          ne(Me, o, u, {
            discrete: !0
          });
        };
        const u = {
          originalEvent: a
        };
        a.pointerType === "touch" ? (t.removeEventListener("click", s.current), s.current = m, t.addEventListener("click", s.current, {
          once: !0
        })) : m();
      } else
        t.removeEventListener("click", s.current);
      n.current = !1;
    }, r = window.setTimeout(() => {
      t.addEventListener("pointerdown", c);
    }, 0);
    return () => {
      window.clearTimeout(r), t.removeEventListener("pointerdown", c), t.removeEventListener("click", s.current);
    };
  }, [
    t,
    o
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function Ke(e, t = globalThis == null ? void 0 : globalThis.document) {
  const o = A(e), n = x(!1);
  return C(() => {
    const s = (c) => {
      c.target && !n.current && ne(Se, o, {
        originalEvent: c
      }, {
        discrete: !1
      });
    };
    return t.addEventListener("focusin", s), () => t.removeEventListener("focusin", s);
  }, [
    t,
    o
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function q() {
  const e = new CustomEvent(H);
  document.dispatchEvent(e);
}
function ne(e, t, o, { discrete: n }) {
  const s = o.originalEvent.target, c = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: o
  });
  t && s.addEventListener(e, t, {
    once: !0
  }), n ? Ne(s, c) : s.dispatchEvent(c);
}
const K = "focusScope.autoFocusOnMount", W = "focusScope.autoFocusOnUnmount", J = {
  bubbles: !1,
  cancelable: !0
}, We = /* @__PURE__ */ g((e, t) => {
  const { loop: o = !1, trapped: n = !1, onMountAutoFocus: s, onUnmountAutoFocus: c, ...r } = e, [a, u] = B(null), m = A(s), l = A(c), f = x(null), S = w(
    t,
    (i) => u(i)
  ), p = x({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  C(() => {
    if (n) {
      let i = function(E) {
        if (p.paused || !a)
          return;
        const h = E.target;
        a.contains(h) ? f.current = h : _(f.current, {
          select: !0
        });
      }, O = function(E) {
        if (p.paused || !a)
          return;
        const h = E.relatedTarget;
        h !== null && (a.contains(h) || _(f.current, {
          select: !0
        }));
      }, b = function(E) {
        if (document.activeElement === document.body)
          for (const I of E)
            I.removedNodes.length > 0 && _(a);
      };
      document.addEventListener("focusin", i), document.addEventListener("focusout", O);
      const F = new MutationObserver(b);
      return a && F.observe(a, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", i), document.removeEventListener("focusout", O), F.disconnect();
      };
    }
  }, [
    n,
    a,
    p.paused
  ]), C(() => {
    if (a) {
      ee.add(p);
      const i = document.activeElement;
      if (!a.contains(i)) {
        const b = new CustomEvent(K, J);
        a.addEventListener(K, m), a.dispatchEvent(b), b.defaultPrevented || (Be(Xe(se(a)), {
          select: !0
        }), document.activeElement === i && _(a));
      }
      return () => {
        a.removeEventListener(K, m), setTimeout(() => {
          const b = new CustomEvent(W, J);
          a.addEventListener(W, l), a.dispatchEvent(b), b.defaultPrevented || _(i ?? document.body, {
            select: !0
          }), a.removeEventListener(W, l), ee.remove(p);
        }, 0);
      };
    }
  }, [
    a,
    m,
    l,
    p
  ]);
  const k = oe((i) => {
    if (!o && !n || p.paused)
      return;
    const O = i.key === "Tab" && !i.altKey && !i.ctrlKey && !i.metaKey, b = document.activeElement;
    if (O && b) {
      const F = i.currentTarget, [E, h] = He(F);
      E && h ? !i.shiftKey && b === h ? (i.preventDefault(), o && _(E, {
        select: !0
      })) : i.shiftKey && b === E && (i.preventDefault(), o && _(h, {
        select: !0
      })) : b === F && i.preventDefault();
    }
  }, [
    o,
    n,
    p.paused
  ]);
  return /* @__PURE__ */ d(T.div, v({
    tabIndex: -1
  }, r, {
    ref: S,
    onKeyDown: k
  }));
});
function Be(e, { select: t = !1 } = {}) {
  const o = document.activeElement;
  for (const n of e)
    if (_(n, {
      select: t
    }), document.activeElement !== o)
      return;
}
function He(e) {
  const t = se(e), o = Q(t, e), n = Q(t.reverse(), e);
  return [
    o,
    n
  ];
}
function se(e) {
  const t = [], o = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (n) => {
      const s = n.tagName === "INPUT" && n.type === "hidden";
      return n.disabled || n.hidden || s ? NodeFilter.FILTER_SKIP : n.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; o.nextNode(); )
    t.push(o.currentNode);
  return t;
}
function Q(e, t) {
  for (const o of e)
    if (!ze(o, {
      upTo: t
    }))
      return o;
}
function ze(e, { upTo: t }) {
  if (getComputedStyle(e).visibility === "hidden")
    return !0;
  for (; e; ) {
    if (t !== void 0 && e === t)
      return !1;
    if (getComputedStyle(e).display === "none")
      return !0;
    e = e.parentElement;
  }
  return !1;
}
function Ge(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function _(e, { select: t = !1 } = {}) {
  if (e && e.focus) {
    const o = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== o && Ge(e) && t && e.select();
  }
}
const ee = Ve();
function Ve() {
  let e = [];
  return {
    add(t) {
      const o = e[0];
      t !== o && (o == null || o.pause()), e = te(e, t), e.unshift(t);
    },
    remove(t) {
      var o;
      e = te(e, t), (o = e[0]) === null || o === void 0 || o.resume();
    }
  };
}
function te(e, t) {
  const o = [
    ...e
  ], n = o.indexOf(t);
  return n !== -1 && o.splice(n, 1), o;
}
function Xe(e) {
  return e.filter(
    (t) => t.tagName !== "A"
  );
}
const Ye = /* @__PURE__ */ g((e, t) => {
  var o;
  const { container: n = globalThis == null || (o = globalThis.document) === null || o === void 0 ? void 0 : o.body, ...s } = e;
  return n ? /* @__PURE__ */ Re.createPortal(/* @__PURE__ */ d(T.div, v({}, s, {
    ref: t
  })), n) : null;
}), ce = "Dialog", [ae, Nt] = Fe(ce), [Ze, D] = ae(ce), qe = (e) => {
  const { __scopeDialog: t, children: o, open: n, defaultOpen: s, onOpenChange: c, modal: r = !0 } = e, a = x(null), u = x(null), [m = !1, l] = Te({
    prop: n,
    defaultProp: s,
    onChange: c
  });
  return /* @__PURE__ */ d(Ze, {
    scope: t,
    triggerRef: a,
    contentRef: u,
    contentId: j(),
    titleId: j(),
    descriptionId: j(),
    open: m,
    onOpenChange: l,
    onOpenToggle: oe(
      () => l(
        (f) => !f
      ),
      [
        l
      ]
    ),
    modal: r
  }, o);
}, Je = "DialogTrigger", Qe = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, ...n } = e, s = D(Je, o), c = w(t, s.triggerRef);
  return /* @__PURE__ */ d(T.button, v({
    type: "button",
    "aria-haspopup": "dialog",
    "aria-expanded": s.open,
    "aria-controls": s.contentId,
    "data-state": V(s.open)
  }, n, {
    ref: c,
    onClick: P(e.onClick, s.onOpenToggle)
  }));
}), re = "DialogPortal", [et, ie] = ae(re, {
  forceMount: void 0
}), tt = (e) => {
  const { __scopeDialog: t, forceMount: o, children: n, container: s } = e, c = D(re, t);
  return /* @__PURE__ */ d(et, {
    scope: t,
    forceMount: o
  }, Ce.map(
    n,
    (r) => /* @__PURE__ */ d(G, {
      present: o || c.open
    }, /* @__PURE__ */ d(Ye, {
      asChild: !0,
      container: s
    }, r))
  ));
}, z = "DialogOverlay", ot = /* @__PURE__ */ g((e, t) => {
  const o = ie(z, e.__scopeDialog), { forceMount: n = o.forceMount, ...s } = e, c = D(z, e.__scopeDialog);
  return c.modal ? /* @__PURE__ */ d(G, {
    present: n || c.open
  }, /* @__PURE__ */ d(nt, v({}, s, {
    ref: t
  }))) : null;
}), nt = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, ...n } = e, s = D(z, o);
  return (
    // Make sure `Content` is scrollable even when it doesn't live inside `RemoveScroll`
    // ie. when `Overlay` and `Content` are siblings
    /* @__PURE__ */ d(we, {
      as: ye,
      allowPinchZoom: !0,
      shards: [
        s.contentRef
      ]
    }, /* @__PURE__ */ d(T.div, v({
      "data-state": V(s.open)
    }, n, {
      ref: t,
      style: {
        pointerEvents: "auto",
        ...n.style
      }
    })))
  );
}), R = "DialogContent", st = /* @__PURE__ */ g((e, t) => {
  const o = ie(R, e.__scopeDialog), { forceMount: n = o.forceMount, ...s } = e, c = D(R, e.__scopeDialog);
  return /* @__PURE__ */ d(G, {
    present: n || c.open
  }, c.modal ? /* @__PURE__ */ d(ct, v({}, s, {
    ref: t
  })) : /* @__PURE__ */ d(at, v({}, s, {
    ref: t
  })));
}), ct = /* @__PURE__ */ g((e, t) => {
  const o = D(R, e.__scopeDialog), n = x(null), s = w(t, o.contentRef, n);
  return C(() => {
    const c = n.current;
    if (c)
      return Le(c);
  }, []), /* @__PURE__ */ d(de, v({}, e, {
    ref: s,
    trapFocus: o.open,
    disableOutsidePointerEvents: !0,
    onCloseAutoFocus: P(e.onCloseAutoFocus, (c) => {
      var r;
      c.preventDefault(), (r = o.triggerRef.current) === null || r === void 0 || r.focus();
    }),
    onPointerDownOutside: P(e.onPointerDownOutside, (c) => {
      const r = c.detail.originalEvent, a = r.button === 0 && r.ctrlKey === !0;
      (r.button === 2 || a) && c.preventDefault();
    }),
    onFocusOutside: P(
      e.onFocusOutside,
      (c) => c.preventDefault()
    )
  }));
}), at = /* @__PURE__ */ g((e, t) => {
  const o = D(R, e.__scopeDialog), n = x(!1), s = x(!1);
  return /* @__PURE__ */ d(de, v({}, e, {
    ref: t,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    onCloseAutoFocus: (c) => {
      var r;
      if ((r = e.onCloseAutoFocus) === null || r === void 0 || r.call(e, c), !c.defaultPrevented) {
        var a;
        n.current || (a = o.triggerRef.current) === null || a === void 0 || a.focus(), c.preventDefault();
      }
      n.current = !1, s.current = !1;
    },
    onInteractOutside: (c) => {
      var r, a;
      (r = e.onInteractOutside) === null || r === void 0 || r.call(e, c), c.defaultPrevented || (n.current = !0, c.detail.originalEvent.type === "pointerdown" && (s.current = !0));
      const u = c.target;
      ((a = o.triggerRef.current) === null || a === void 0 ? void 0 : a.contains(u)) && c.preventDefault(), c.detail.originalEvent.type === "focusin" && s.current && c.preventDefault();
    }
  }));
}), de = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, trapFocus: n, onOpenAutoFocus: s, onCloseAutoFocus: c, ...r } = e, a = D(R, o), u = x(null), m = w(t, u);
  return Ae(), /* @__PURE__ */ d(Pe, null, /* @__PURE__ */ d(We, {
    asChild: !0,
    loop: !0,
    trapped: n,
    onMountAutoFocus: s,
    onUnmountAutoFocus: c
  }, /* @__PURE__ */ d(Ue, v({
    role: "dialog",
    id: a.contentId,
    "aria-describedby": a.descriptionId,
    "aria-labelledby": a.titleId,
    "data-state": V(a.open)
  }, r, {
    ref: m,
    onDismiss: () => a.onOpenChange(!1)
  }))), !1);
}), rt = "DialogTitle", it = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, ...n } = e, s = D(rt, o);
  return /* @__PURE__ */ d(T.h2, v({
    id: s.titleId
  }, n, {
    ref: t
  }));
}), dt = "DialogDescription", lt = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, ...n } = e, s = D(dt, o);
  return /* @__PURE__ */ d(T.p, v({
    id: s.descriptionId
  }, n, {
    ref: t
  }));
}), ut = "DialogClose", ft = /* @__PURE__ */ g((e, t) => {
  const { __scopeDialog: o, ...n } = e, s = D(ut, o);
  return /* @__PURE__ */ d(T.button, v({
    type: "button"
  }, n, {
    ref: t,
    onClick: P(
      e.onClick,
      () => s.onOpenChange(!1)
    )
  }));
});
function V(e) {
  return e ? "open" : "closed";
}
const $t = qe, pt = Qe, le = tt, ue = ot, fe = st, $e = it, pe = lt, bt = ft, vt = $t, mt = pt, be = ({
  ...e
}) => /* @__PURE__ */ y.jsx(le, { ...e });
be.displayName = le.displayName;
const ve = M.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ y.jsx(
  ue,
  {
    ref: o,
    className: N(
      "fixed inset-0 z-dialog bg-black-primary/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      e
    ),
    ...t
  }
));
ve.displayName = ue.displayName;
const me = M.forwardRef(({ className: e, children: t, ...o }, n) => /* @__PURE__ */ y.jsxs(be, { children: [
  /* @__PURE__ */ y.jsx(ve, {}),
  /* @__PURE__ */ y.jsx(
    fe,
    {
      ref: n,
      className: N(
        "fixed max-h-screen left-[50%] top-[50%] z-dialog grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        e
      ),
      ...o,
      children: t
    }
  )
] }));
me.displayName = fe.displayName;
const ge = ({
  className: e,
  ...t
}) => /* @__PURE__ */ y.jsx(
  "div",
  {
    className: N(
      "flex flex-col py-3 px-4 text-center sm:text-left border-b",
      e
    ),
    ...t
  }
);
ge.displayName = "Header";
const Ee = ({
  className: e,
  ...t
}) => /* @__PURE__ */ y.jsx(
  "div",
  {
    className: N(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 border-t px-3 py-2",
      e
    ),
    ...t
  }
);
Ee.displayName = "Footer";
const he = M.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ y.jsx(
  $e,
  {
    ref: o,
    className: N(
      "text-black-primary text-md font-normal leading-none tracking-tight",
      e
    ),
    ...t
  }
));
he.displayName = $e.displayName;
const xe = M.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ y.jsx(
  pe,
  {
    ref: o,
    className: N("px-3", e),
    ...t
  }
));
xe.displayName = pe.displayName;
const It = Object.assign(vt, {
  Content: me,
  Description: xe,
  Footer: Ee,
  Header: ge,
  Title: he,
  Trigger: mt,
  Close: bt
});
export {
  It as default
};
