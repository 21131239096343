import * as React from "react"

export const Folder = (props) => (
    <svg
        width={20}
        height={16}
        fill="none"
        data-testid="Folder"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.668 7.167V1.834a.5.5 0 0 1 .5-.5h5.148a.5.5 0 0 1 .325.12l2.637 2.26a.5.5 0 0 0 .325.12h7.232a.5.5 0 0 1 .5.5v2.833m-16.667 0v7a.5.5 0 0 0 .5.5h15.667a.5.5 0 0 0 .5-.5v-7m-16.667 0h16.667"
            stroke="currentColor"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
