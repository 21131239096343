import { styled } from "@mui/material/styles";
import { themeV2 } from "src/theme/mysherpas-theme-option";

const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: themeV2.colors.yellow[10],
    padding: theme.spacing(2, 0),


}))

const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: themeV2.neutral.grey[200],
    fontSize: theme.typography.pxToRem(14),
}))

const IconWrapper = styled('div')(({ theme }) => ({
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: themeV2.colors.yellow[100],
    color: 'white',
    padding: theme.spacing(.5),
}))

const Title = styled('div')(({ theme }) => ({
    fontWeight: 500,
    color: 'black'
}))

export const DowntimeAlertStyles = {
    Wrapper,
    Content,
    IconWrapper,
    Title
}