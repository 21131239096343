import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "components/dist/atoms/Button";
import Dialog from "components/dist/atoms/Dialog";
import Text from "components/dist/atoms/Text";
import { useEffect, useRef, useState } from "react";
import { api } from "src/api";
import { TimezoneDTO } from "src/backend";
import { useUserTimezoneMode } from "src/hooks/use-user-timezone-mode";
import { ExclamationCircleGraphic } from "src/icons/exclamation-circle-graphic";
import { setTimezoneId } from "src/slices/ui";
import { selectUserPreference, updateUserPreferences } from "src/slices/user";
import { useDispatch, useSelector } from "src/store";

import { TimezoneCheckerStyles } from "./timezone-checker.styles";

export const TimezoneChecker = () => {
    const dispatch = useDispatch();
    const checkboxReference = useRef(null); // Initialize the reference
    const [newTimeZone, setSelectedTimezone] = useState<TimezoneDTO>();
    const userPreferences = useSelector(selectUserPreference)

    const { mode, loading, onChange } = useUserTimezoneMode();
    // the currently set timezone in the user's profile
    const preferenceTimezone = userPreferences.timezoneId;
    // fetch timezones
    useEffect(() => {
        if (!loading && mode !== 'manual') {
            (async () => {
                const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
                if (preferenceTimezone === timeZone) {
                    return;
                }
                const timezones = await api.getTimezones();
                const newTimezone = timezones.find((timezone) => timezone.id === timeZone);
                // if we detect a timezone mismatch, we need to update the user's timezone
                if (newTimezone && newTimezone.id !== preferenceTimezone) {
                    // if the user selected automatic timezone detection, we can update the user's timezone automatically
                    if (mode === 'auto') {
                        dispatch(updateUserPreferences({
                            timezoneId: timeZone
                        }))
                        dispatch(setTimezoneId(timeZone));
                    } else if (mode === 'ask') {
                        // otherwise, we need to prompt the user to update their timezone
                        setSelectedTimezone(newTimezone);
                    }
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, mode, preferenceTimezone]);

    const updateTimeZone = async () => {
        dispatch(updateUserPreferences({
            timezoneId: newTimeZone.id
        }))
        dispatch(setTimezoneId(newTimeZone.id));
    }

    const onNoClick = async () => {
        if (checkboxReference.current?.checked) {
            await onChange({
                mode: 'manual',
            });
        }
        setSelectedTimezone(undefined);
    }

    const onYesClick = async () => {
        if (checkboxReference.current?.checked) {
            await onChange({
                mode: 'auto',
            });
        }
        await updateTimeZone();
        setSelectedTimezone(undefined);
    }

    const handleOpenChange = (open: boolean) => {
        if (!open) {
            onNoClick();
        }
    }

    if (!newTimeZone) {
        return null;
    }

    return <Dialog
        onOpenChange={handleOpenChange}
        open={!!newTimeZone}>
        <Dialog.Content>
            <TimezoneCheckerStyles.Container>
                <ExclamationCircleGraphic />
                <TimezoneCheckerStyles.Description>
                    <Text>Change time zone to</Text>
                    <Text>{newTimeZone.description} ?</Text>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                inputRef={checkboxReference}
                            />
                        )}
                        sx={{
                            '& .MuiTypography-root': {
                                fontWeight: 500,
                            }
                        }}
                        label="Don’t ask me again"
                    />
                </TimezoneCheckerStyles.Description>
                <TimezoneCheckerStyles.Actions>
                    <Button
                        onClick={onNoClick}
                        variant='outline'>
                        No
                    </Button>
                    <Button
                        onClick={onYesClick}
                        variant='outline'>
                        Yes
                    </Button>
                </TimezoneCheckerStyles.Actions>
            </TimezoneCheckerStyles.Container>
        </Dialog.Content>
    </Dialog>
};