import * as React from "react"

export const CheckMarkCircle = (props) => (
    <svg
        width={18}
        height={18}
        fill="none"
        data-testid="CheckMarkCircle"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m6 8 3 3 8-8"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17 9a8 8 0 1 1-5.3-7.533"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);